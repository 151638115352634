import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroWithoutImage from "../components/Hero/HeroWithoutImage"
//import Paginator from "../components/Paginator";
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

const ResourcesOutput = ({ resourcesList, type }) => {
  let CTAText

  switch (type.toLowerCase()) {
    case "white-papers":
    case "analyst-reports-reviews":
    case "ebooks":
    case "guides":
      CTAText = "Read more"
      break
    case "infographics":
      CTAText = "View"
      break
    case "videos":
      CTAText = "Watch video"
      break
    case "datasheets":
      CTAText = "Read datasheet"
      break
  }

  return resourcesList.map((res, i) => (
    <div key={`section-${i}`}>
      <Link
        to={`/resources/${type}/${res.node.uid}`}
        className="uk-display-block uk-card uk-card-default uk-card-small uk-link"
      >
        <div className="uk-card-media-top">
          <Image
            src={res.node.data.thumbnail.fixed.src}
            srcSet={res.node.data.thumbnail.fixed.srcSet}
            alt={res.node.data.title ? res.node.data.title.text : res.node.data.hero_title}
            style={{
              height: "240px",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div>
          <div className="uk-card-body uk-text-left">
            <p className="uk-card-title">
              {res.node.data.title ? res.node.data.title.text : res.node.data.hero_title.text}
            </p>
          </div>
          <div className="uk-card-footer uk-text-left">
            <p className="cta-link">
              {CTAText} <span uk-icon="icon: arrow-right"></span>
            </p>
          </div>
        </div>
      </Link>
    </div>
  ))
}

const Resources = ({ data, location, ...props }) => {
  const path = props.pathContext.uid
  const url = typeof window !== "undefined" ? window.location.href : ""
  // const type = url.split('/').pop();
  var titleString, contextList

  switch (path.toLowerCase()) {
    case "white-papers":
      titleString = "Whitepapers"
      contextList = data.allPrismicWhitepapers.edges
      break
    case "ebooks":
      titleString = "eBooks"
      contextList = data.allPrismicEbooks.edges
      break
    case "datasheets":
      titleString = "Datasheets"
      contextList = data.allPrismicDatasheets.edges
      break
    case "infographics":
      titleString = "Infographics"
      contextList = data.allPrismicInfographics.edges
      break
    case "videos":
      titleString = "Videos"
      contextList = data.allPrismicVideo.edges
      break
    case "analyst-reports-reviews":
      titleString = "Reports and Reviews"
      contextList = data.allPrismicReports.edges
      break
    case "guides":
      titleString = "Guides"
      contextList = data.allPrismicGuidePage.edges
      break
    default:
      titleString = "Materials"
  }

  return (
    <Layout>
      <SEO
        title={data.prismicResourceCategories.data.seo_title}
        keywords={data.prismicResourceCategories.data.seo_keywords}
        description={data.prismicResourceCategories.data.seo_description}
      />
      <HeroWithoutImage title={`Resource Center / ${titleString}`} />
      <section className="uk-section">
        <div className="uk-container">
          <div
            className="uk-grid-column-medium uk-child-width-1-3@s uk-text-center uk-margin-top uk-margin-large-bottom"
            data-uk-grid
            data-uk-height-match="target: > div > .uk-card > div .uk-card-body"
          >
            {typeof contextList !== "undefined" && (
              <ResourcesOutput resourcesList={contextList} type={path} />
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Resources)
export const resourcesCategoryQuery = graphql`
  query resourcesCategoryQuery($uid: String) {
    prismicResourceCategories(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_keywords
        seo_description
      }
    }
    allPrismicWhitepapers(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicEbooks(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicReports(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicDatasheets(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            link {
              link_type
              target
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicInfographics(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicVideo(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            hero_title {
              html
              text
            }
          }
          uid
          last_publication_date
        }
      }
    }
    allPrismicGuidePage(sort: { fields: last_publication_date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            thumbnail {
              fixed(width: 800) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            title {
              html
              text
            }
            description {
              html
            }
          }
          uid
          last_publication_date
        }
      }
    }
  }
`

/*   <Paginator context={props.pageContext} path={`/resources/${posts[0][0].node.data.resource_category.uid}`} /> */
